/* DayPicker styles */

.DayPicker {
	display: inline-block;
	font-size: 0.8em;
}

.DayPicker-wrapper {
	position: relative;
	flex-direction: row;
	padding-bottom: 1em;

	user-select: none;
}

.DayPicker-Btn {
	font-size: 0.9em;
	@apply rounded-md;
	cursor: pointer;
	@apply bg-transparent h:bg-cyan-500 dark:h:bg-cyan-500;
	@apply bg-opacity-50 h:bg-opacity-50 dark:bg-opacity-50 dark:h:bg-opacity-50;
	@apply gray-600 dark:gray-400 h:white dark:h:white;
	@apply transition-all;
}

.DayPicker-Root {
	margin: 0 !important;
}

.DayPicker-Months {
}

.DayPicker-Month {
	display: table;
	border-spacing: 0;
	border-collapse: collapse;
	user-select: none;
	@apply w-full;
}

.DayPicker-NavBar {
	@apply flex;
	@apply justify-between;
	@apply items-center;
	@apply space-x-2;
}

.DayPicker-NavButton {
	width: 2em;
	height: 2em;
	@apply flex;
	@apply justify-center;
	@apply items-center;
	@apply bg-transparent h:bg-gray-500 dark:h:bg-gray-700;
}

.DayPicker-NavButton--interactionDisabled {
	display: none;
}

.DayPicker-Caption {
}

.DayPicker-Table {
	@apply w-full;
}

.DayPicker-Weekdays {
	display: table-header-group;
	margin-top: 1em;
}

.DayPicker-WeekdaysRow {
	display: table-row;
}

.DayPicker-Weekday {
	display: table-cell;
	padding: 0.5em;
	text-align: center;
	font-size: 0.8em;
}

.DayPicker-Weekday abbr[title] {
	border-bottom: none;
	text-decoration: none;
}

.DayPicker-Body {
	display: table-row-group;
}

.DayPicker-Week {
	display: table-row;
}

.DayPicker-Day {
	padding: 0.5em;
	vertical-align: middle;
	text-align: center;
}

.DayPicker--interactionDisabled .DayPicker-Day {
	cursor: default;
}

.DayPicker-Footer {
	padding-top: 0.5em;
}

.DayPicker-TodayButton {
	border: none;
	background-color: transparent;
	background-image: none;
	box-shadow: none;
	cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
	font-weight: 700;
}

.DayPicker-Day--outside {
	color: #8b9898;
	cursor: default;
}

.DayPicker-Day--disabled {
	color: #dce0e0;
	cursor: default;
}

/* Example modifiers */

.DayPicker-Day--sunday {
	background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
	color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
	position: relative;
	background-color: #4a90e2;
	color: #f0f8ff;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
	background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled)
	.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
	background-color: #f0f8ff;
}

/* DayPickerInput */

.DayPickerInput {
	display: inline-block;
}

.DayPickerInput-OverlayWrapper {
	position: relative;
}

.DayPickerInput-Overlay {
	position: absolute;
	left: 0;
	z-index: 1;
	background: white;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
