@import './_day-picker.css';
@import './_emoji-picker.css';
@import './_github.css';
@import './_markdown.css';
@import './_mdx-editor.css';
@import './_nprogress.css';
@import './_skeleton.css';
@import './_swiper.css';
@import './_quill.css';
@import './_stars.css';

@tailwind base;
@tailwind utilities;
@tailwind components;

@layer base {
	html {
		overflow-x: hidden;
	}
	body {
		@apply w-full;
	}
	div#__next {
		@apply gray-700 dark:gray-200;
	}
	h1, h2, h3, h4, h5, h6 {
		@apply gray-700 dark:gray-200;
	}
	h1 {
		@apply text-6xl;
	}
	h2 {
		@apply text-5xl;
	}
	h3 {
		@apply text-3xl;
	}
	h4 {
		@apply text-2xl;
	}
	h5 {
		@apply text-lg;
	}
	h6 {
		@apply text-base;
	}
	p {
		@apply gray-600 dark:gray-300;
		@apply text-base;
	}
	small {
		@apply gray-600 dark:gray-300;
		@apply text-sm;
	}
	.text-inherit {
		color: inherit;
	}
	.fs-inherit {
		font-size: inherit;
	}
	.fw-inherit {
		font-weight: inherit;
	}
	.s-inherit {
		stroke: inherit;
	}
	.inherit {
		color: inherit;
		font-size: inherit;
		font-weight: inherit;
	}
	.ltr {
		direction: ltr;
	}
	.rtl {
		direction: rtl;
	}

	/*  input:focus-visible {
    outline: none;
  }*/

	input[type='search']::-webkit-search-decoration,
	input[type='search']::-webkit-search-cancel-button,
	input[type='search']::-webkit-search-results-button,
	input[type='search']::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}
}

@layer utilities {
	/* Flex */
	.flex-start {
		@apply flex;
		@apply justify-start;
		@apply items-center;
	}

	.flex-start-top {
		@apply flex;
		@apply justify-start;
		@apply items-start;
	}

	.flex-start-bottom {
		@apply flex;
		@apply justify-start;
		@apply items-end;
	}

	.flex-between {
		@apply flex;
		@apply justify-between;
		@apply items-center;
	}

	.flex-between-inline {
		@apply inline-flex;
		@apply justify-between;
		@apply items-center;
	}

	.flex-between-top {
		@apply flex;
		@apply justify-between;
		@apply items-start;
	}

	.flex-between-bottom {
		@apply flex;
		@apply justify-between;
		@apply items-end;
	}

	.flex-center {
		@apply flex;
		@apply items-center;
	}

	.flex-middle {
		@apply flex;
		@apply justify-center;
		@apply items-center;
	}

	.flex-middle-inline {
		@apply inline-flex;
		@apply justify-center;
		@apply items-center;
	}

	.flex-end {
		@apply flex;
		@apply justify-end;
		@apply items-center;
	}

	/* Flex Column */
	.flex-column {
		@apply flex;
		@apply flex-col;
	}
}

/*
Docs
https://tailwindcss.com/docs/preflight
*/
