@import 'react-quill-new/dist/quill.snow.css';

/* Quill */

/* Toolbar */
.quill .ql-toolbar {
	@apply border-t-0 border-l-0 border-r-0;
	@apply !border-b;
	@apply border-gray-400;
	@apply border-opacity-25;
	@apply gray-600 dark:gray-400;
	@apply flex;
	@apply justify-start;
	@apply items-center;
	@apply !space-x-4;
	@apply px-2 py-2;
}

.quill .ql-toolbar .ql-formats {
	@apply flex;
	@apply justify-start;
	@apply items-center;
	@apply space-x-0;
	@apply !mr-0;
}

.quill .ql-toolbar .ql-formats button {
	@apply w-8 h-8;
	@apply p-2;
	@apply flex;
	@apply justify-between;
	@apply items-center;
	@apply float-none;
	@apply opacity-50 h:opacity-100;
	@apply gray-700 dark:gray-300 h:blue-500 dark:h:cyan-500;
	@apply h:bg-gray-100 dark:h:bg-gray-800;
	@apply rounded-md;
}

.quill .ql-toolbar .ql-formats button .ql-stroke {
	@apply gray-700 dark:gray-300 h:blue-500 dark:h:cyan-500;
}

.quill .ql-toolbar .ql-formats button[value='-1'] {
	transform: rotateY(180deg);
}

.quill .ql-toolbar .ql-formats .ql-picker-label {
	@apply p-2;
	@apply border-none;
	@apply flex;
	@apply justify-between;
	@apply items-center;
	@apply float-none;
	@apply opacity-50 h:opacity-100;
	@apply gray-700 dark:gray-300 h:blue-500 dark:h:cyan-500;
	@apply h:bg-gray-100 dark:h:bg-gray-800;
	@apply rounded-md;
}

.quill .ql-toolbar .ql-formats .ql-picker-options {
	@apply bg-gray-100 dark:bg-gray-800;
	@apply border-none;
	@apply rounded-md;
	@apply gray-700 dark:gray-300 h:blue-500 dark:h:cyan-500;
	@apply h:bg-gray-100 dark:h:bg-gray-800;
}

.quill .ql-toolbar .ql-formats .ql-picker-item {
	@apply gray-700 dark:gray-300 h:blue-500 dark:h:cyan-500;
	@apply h:bg-gray-100 dark:h:bg-gray-800;
}

/* Mention */
.ql-mention-list-container {
	@apply z-10;
	@apply bg-gray-100 dark:bg-gray-900;
	@apply border;
	@apply border-gray-200 dark:border-gray-700;
	@apply rounded-md;
	@apply shadow-lg;
	@apply p-2;
	@apply overflow-hidden overflow-y-scroll;
	@apply max-w-[300px];
	@apply max-h-[400px];
}

.ql-mention-list {
	@apply space-y-1;
}

.ql-mention-list-item {
	@apply w-full;
}

.ql-mention-list-item span {
	@apply h:bg-gray-100 dark:h:bg-gray-800;
	@apply rounded-md;
	@apply px-2 py-1;
	@apply cursor-pointer;
	@apply transition-all;
	@apply block;
	@apply w-full;
}

.ql-mention-list-item.selected span {
	@apply bg-gray-100 dark:bg-gray-800;
}

.ql-mention-list-item.selected div[data-name='Avatar'] {
	@apply bg-gray-100 dark:bg-gray-800;
}

.ql-editor .mention {
	@apply bg-gray-100 dark:bg-gray-800;
	@apply rounded-md;
	@apply px-1 py-1;
	@apply mx-0.5;
	@apply blue-500 dark:cyan-500;
}

/* Container */
.quill .ql-container {
	@apply !border-none;
	@apply outline-none;
	@apply px-4 py-3.5;
}

.ql-editor {
	@apply text-sm;
	@apply min-h-[100px];
	margin: 0;
	padding: 0;
}

.ql-editor p {
	@apply text-sm;
	line-height: 1.5rem;
}

.ql-editor p .ql-size-h1 {
	@apply text-4xl;
}

.ql-editor p .ql-size-h2 {
	@apply text-3xl;
}

.ql-editor p .ql-size-h3 {
	@apply text-2xl;
}

.ql-editor p .ql-size-h4 {
	@apply text-2xl;
}

.ql-editor p .ql-size-h5 {
	@apply text-2xl;
}

.ql-editor p .ql-size-h6 {
	@apply text-2xl;
}

.ql-editor p .ql-size-p {
	@apply text-2xl;
}

.ql-editor p .ql-size-sm {
	@apply text-sm;
}

.ql-editor ol {
	@apply text-sm;
	padding-left: 1.5em;
	@apply leading-relaxed;
}

.ql-editor ol li {
	font-size: inherit;
	color: inherit;
	@apply pl-0;
}

.ql-editor ul {
	@apply text-sm;
	padding-left: 1.5em;
	@apply leading-relaxed;
}

.ql-editor ul li {
	list-style-type: disc;
	font-size: inherit;
	color: inherit;
	@apply pl-0;
}

.ql-editor ul li:before {
	@apply text-base;
}

.ql-editor blockquote {
	@apply !border-l-2;
	@apply border-gray-300 dark:border-gray-600;
}

.ql-editor code {
	@apply bg-gray-200 dark:bg-gray-800;
	color: inherit;
}

.ql-editor pre {
	@apply !bg-gray-200 dark:!bg-gray-800;
	@apply w-full;
	@apply inline-block;
	@apply !overflow-hidden;
	color: inherit;
}

.ql-editor.ql-blank:before {
	@apply gray-500;
	position: relative;
	font-style: normal;
	left: 0;
	right: 0;
}

/* Display */
.quill-display {
	@apply gray-700 dark:gray-300;
	@apply font-secondary;
	@apply font-normal;
	@apply text-sm;
	@apply tracking-wide;
}

.quill-display ol {
	padding-left: 1.5em;
	@apply leading-relaxed;
}

.quill-display ul {
	padding-left: 1.5em;
}

.quill-display ul li {
	list-style-type: disc;
	font-size: inherit;
	color: inherit;
	@apply leading-relaxed;
}

.quill-display ol li {
	list-style-type: decimal;
	font-size: inherit;
	color: inherit;
	@apply leading-relaxed;
}

.quill-display p {
	font-size: inherit;
	color: inherit;
}

.quill-display a {
	@apply blue-500 ;
	@apply h:underline;
}

.quill-display blockquote {
	@apply !border-l-2;
	@apply border-gray-300 dark:border-gray-600;
}

.quill-display code {
	@apply bg-gray-200 dark:bg-gray-800;
	color: inherit;
}

.quill-display pre {
	@apply !bg-gray-200 dark:!bg-gray-800;
	@apply rounded-md;
	@apply p-2;
	color: inherit;
}

.quill-display .mention {
	@apply bg-gray-100 dark:bg-gray-800;
	@apply rounded-md;
	@apply px-1 py-1;
	@apply mx-0.5;
	@apply blue-500 dark:cyan-500;
}
