/* Make clicks pass-through */
#nprogress {
	pointer-events: none;
}

#nprogress .bar {
	@apply bg-gradient-to-r from-cyan-500 to-indigo-500;
	@apply shadow-sm;
	@apply h-1;
	@apply fixed;
	@apply z-20;
	@apply top-0;
	@apply left-0;
	@apply w-full;
}
