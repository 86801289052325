.markdown {
	@apply font-primary;
}

.markdown h1,
h2,
h3,
h4,
h5,
h6,
p {
	@apply block;
	@apply leading-normal;
}

.markdown h1 {
	@apply text-5xl;
	@apply font-bold;
	@apply gray-800;
	@apply dark:gray-200;
	@apply pb-4;
	@apply mb-4;
	@apply border-b border-gray-400 dark:border-gray-600;
	@apply border-opacity-25 dark:border-opacity-25;
}

.markdown h2 {
	@apply text-4xl;
	@apply font-semibold;
	@apply gray-800;
	@apply dark:gray-200;
	@apply mb-4;
}

.markdown h3 {
	@apply text-3xl;
	@apply font-semibold;
	@apply gray-800;
	@apply dark:gray-200;
	@apply mb-4;
}

.markdown h4 {
	@apply text-2xl;
	@apply font-semibold;
	@apply gray-800;
	@apply dark:gray-200;
	@apply mb-4;
}

.markdown h5 {
	@apply text-xl;
	@apply font-semibold;
	@apply gray-800;
	@apply dark:gray-200;
	@apply mb-4;
}

.markdown h6 {
	@apply text-lg;
	@apply font-semibold;
	@apply gray-800;
	@apply dark:gray-200;
	@apply mb-4;
}

.markdown p {
	@apply text-lg;
	@apply font-light;
	@apply font-secondary;
	@apply leading-8;
	@apply gray-700 dark:gray-300;
	@apply mb-4;
}

.markdown small {
	@apply text-base;
	@apply font-normal;
	@apply font-secondary;
	@apply leading-6;
	@apply gray-500;
	@apply dark:gray-500;
	@apply mb-4;
}

.markdown a {
	@apply font-normal;
	@apply font-secondary;
	@apply leading-6;
	@apply blue-500 h:blue-400  dark:cyan-500 dark:h:cyan-400;
	@apply underline;
	@apply mb-4;
}

.markdown img {
	@apply rounded-md;
}

.markdown strong {
	@apply font-bold;
}

.markdown hr {
	@apply mt-6;
	@apply border-gray-200 dark:border-gray-800;
	@apply pb-6;
}

.markdown ol {
	@apply pl-4 pb-4;
}

.markdown ul {
	@apply pl-4 pb-4;
}

.markdown li {
	@apply gray-700 dark:gray-300;
	@apply pl-2;
	@apply mb-1;
	@apply text-base;
	@apply font-light;
	@apply font-secondary;
	@apply leading-8;
}

.markdown ul li {
	list-style-type: disc;
}

.markdown ol li {
	/* list-style-type: disc; */
}

.markdown pre {
	@apply bg-gray-100 dark:bg-gray-800;
	@apply border;
	@apply border-gray-200 dark:border-gray-700;
	@apply rounded-md;
	@apply p-3;
}

.markdown code {
	@apply bg-gray-100 dark:bg-gray-800;
	@apply gray-600 dark:gray-300;
	/* @apply border; */
	@apply border-gray-200 dark:border-gray-700;
	@apply border-opacity-25;
	@apply text-sm;
	@apply px-2 py-1;
	@apply rounded-md;
}
