.mdx-editor .mdxeditor-root-contenteditable {
	@apply h-full;
}

.mdx-editor .mdxeditor-toolbar {
	@apply bg-white dark:bg-gray-900;
	@apply border-none;
	@apply rounded-none;
	@apply px-0;
}

.mdx-editor .markdown {
	@apply px-4;
}

.mdx-editor .sp-wrapper {
	@apply bg-gray-100 dark:bg-gray-800;
	@apply border;
	@apply border-gray-200 dark:border-gray-700;
	@apply border-opacity-25;
	@apply rounded-md;
}

.mdx-editor .cm-editor {
	@apply bg-gray-100 dark:bg-gray-800;
}

.mdx-editor code {
	@apply bg-gray-100 dark:bg-gray-800;
	@apply border;
	@apply border-gray-200 dark:border-gray-700;
	@apply border-opacity-25;
	@apply rounded-md;
	@apply text-sm;
	@apply px-2 py-0.5;
	@apply text-pink-500;
}

.mdx-editor code span {
	@apply bg-transparent;
}

.mdxeditor-popup-container div {
	@apply bg-gray-100 dark:bg-gray-800;
	@apply border;
	@apply border-gray-200 dark:border-gray-700;
	@apply border-opacity-25;
	@apply rounded-md;
}

.mdxeditor-popup-container div div {
	@apply border-none;
}

.mdxeditor-popup-container form {
	@apply border-none;
}

.mdxeditor-popup-container form div {
	@apply border-none;
}

.mdxeditor-popup-container input {
	@apply transition;
	@apply ease-in;
	@apply bg-white dark:bg-gray-900;
	@apply border;
	@apply border-gray-200 dark:border-gray-700;
	@apply border-opacity-25;
	@apply rounded-md;
	@apply px-4 py-2;
}

.mdxeditor-popup-container button[type='submit'] {
	@apply bg-gradient-to-r from-cyan-500 to-indigo-500;
	@apply bg-pos-0 h:bg-pos-100;
	@apply bg-size-200;
	@apply border-transparent;
	@apply text-white;
}

.mdxeditor-popup-container button[type='reset'] {
	@apply bg-gray-200 dark:bg-gray-800;
	@apply bg-opacity-0 dark:bg-opacity-0;
	@apply border-transparent;
	@apply gray-500 dark:gray-300;
	@apply hover:bg-opacity-100 dark:hover:bg-opacity-100;
}

.mdx-editor .mdxeditor-diff-editor .cm-editor {
	@apply bg-transparent;
	@apply text-gray-700 dark:gray-200;
}

.mdx-editor .mdxeditor-source-editor .cm-editor {
	@apply bg-transparent;
	@apply text-gray-700 dark:gray-200;
}

.mdx-editor .mdxeditor-source-editor .cm-gutters {
	@apply bg-transparent;
	@apply text-gray-600 dark:gray-500;
}

.mdx-editor .mdxeditor-source-editor .cm-gutters .cm-gutterElement {
	@apply bg-transparent;
}

.mdx-editor .mdxeditor-source-editor span {
	@apply bg-transparent;
	@apply text-gray-600 dark:gray-400;
}
